import { useAppStore } from '~/stores/AppStore'

export default function () {
  const isLocked = useState<boolean>('bodyLock', () => false)
  const store = useAppStore()

  function bodyLock() {
    isLocked.value = true
    document.body.classList.add('overflow-hidden')
    store.setLockSmoothScroll(true)
  }

  function bodyUnlock() {
    isLocked.value = false
    store.setLockSmoothScroll(false)
    document.body.classList.remove('overflow-hidden')
  }

  return {
    bodyLock,
    bodyUnlock
  }
}
